import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import React, { useState } from "react";
import { useRef } from "react";
import { Translate } from "react-auto-translate";
import Calendar from "react-calendar";

const DOB = (props) => {

  // States
  const [showCalendar, setShowCalendar] = useState(false);

  const handleChange = (value) => {
    console.log("event value: ", value);
    if (props?.item?.dataSet?.isRequired) {
      props.item.dataSet.isError = true
      props.item.dataSet.errorMessage = 'Required'
    }

    props.item.value = value
    props?.validateData()
  }

  const handelBlur = (value) => {
    props.item.value = value
    props?.validateData()
  }

    // // For Date Picker
    // const interviewDateRef = useRef();
    // const handleInterviewDateClick = () => {
    //   const fallbackPicker = document.querySelector('.fallbackDatePicker');
    //   console.log("clicked:--------------------------------------------------------");
    //   fallbackPicker.style.display = 'none';
    //   interviewDateRef.current.focus();
    // };
  

    return (
        <div className={`single-group-child no-shadow ${props?.['formType']} dob ${props?.completeQuestionData?.isVisible == false ? 'hide-question' : null} ${props?.className} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
        <strong className={`strongclass ${props?.['formType']}`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
        <div className={`ui form ${props?.['formType']}`}>
          <div className='field'>
            {/* <input type={'date'} className='textcomponent pl-2'
              name={props?.item?.groupName} 
              style={{height: props?.['formType'] === "soulful-application" ? '53px' : '53px'}}
              defaultValue={props.item.value}
              max={moment().format('YYYY-MM-DD')}
              data-date-format="DD/MM/YYYY"
              disabled={props?.item?.dataSet?.formDataKey !== "email" ? props?.btnDisabled : false}
              onChange={(event) => {handleChange(event)}}
              onBlur={(event) => {handelBlur(event.target.value); (this.type='text')}}
              >
            </input> */}
            <div style={{height: '53px', border: '1px solid rgba(34,36,38,.15)', borderRadius: '4px', width: '100%', paddingTop: '14px', paddingLeft: '10px', cursor: 'pointer'}} className='relative pointer' onClick={() => setShowCalendar(!showCalendar)}>
              <span style={{fontSize: '14px'}}>
                {props?.item?.value ? moment(props?.item?.value).format('DD-MM-YYYY') : 'DD-MM-YYYY' || 'DD-MM-YYYY'}
              </span>
            </div>
            {showCalendar && <Calendar
            className='textcomponent pl-2 w-full position-absolute top-10 left-0 high-zindex'
            style={{height: 'px'}}
            maxDate={new Date()}
            onChange={(event) => {handleChange(event); setShowCalendar(false)}} value={props.item.value} />
            }
               {/* <DatePicker
                wrapperClassName="textcomponent w-full custom-datepicker"
                className='textcomponent pl-2 w-full datepicker'
                style={{height: props?.['formType'] === "soulful-application" ? '53px' : '53px'}}
                maxDate={moment().format('YYYY-MM-DD')}
                disabled={props?.item?.dataSet?.formDataKey !== "email" ? props?.btnDisabled : false}
                selected={props.item.value} dateFormat={'dd-MM-yyyy'} placeholderText="DD-MM-YYYY" onChange={(date) => handleChange(date)}
                showIcon={true}
                showYearDropdown={true}
                icon={<svg
                  className="custom-datepicker-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 48 48"
                >
                  <mask id="ipSApplication0">
                    <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                      <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                      <path
                        fill="#fff"
                        d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                      ></path>
                    </g>
                  </mask>
                  <path
                    fill="currentColor"
                    d="M0 0h48v48H0z"
                    mask="url(#ipSApplication0)"
                  ></path>
                </svg>}
                >
              </DatePicker> */}
          </div>
          <div>
              {/* <p>
                {props?.item?.notifyMe && props?.item?.dataSet?.formDataKey !== "email" ? 
                  <input className='mx-1' type={'checkbox'} checked={props?.item?.dataSet?.formDataKey === "email" ? props?.item?.emailCommunication : props?.item?.smsCommunication} value="" onChange={(event) => handleCommunicationDetails(event.target.checked)}></input>
                  : null
                }
                {props?.item?.notifyMe ? props?.item?.dataSet?.formDataKey === "email" ? "This is the email you will use to register and log on in the future" : 
                props?.item?.dataSet?.formDataKey === "phoneNumber" ?
                "Send me SMS notifications about training, certifications, career services, job opportunities." : '' : ''
                }
                </p> */}
          </div>
       </div> 
    </div>
    )
}

export default DOB