import React from 'react'
import {Routes,Route, useLocation, Router, Navigate } from 'react-router-dom';
import App from './App'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Elevatorpitchform from './Forms/Elevatorpitchform';
import Pretraining from './Forms/Pretraining';
import Posttraining from './Forms/Posttraining';
import Occupationform from './Forms/Occupationform';
import Studentuploads from './Forms/Studentuploads';
import './App.css'
import StudentApplication from './Forms/studentApplication';
import ADNYApplication from './Forms/adnyForm';
import WeActStudentApplication from './Forms/WeActForms/studentApplications';
import WeActFooter from './Forms/WeActForms/WeActComponents/Footer';
import ReactGA from 'react-ga4';
import TestStudentApplication from './Forms/testStudentApplications';
import PageNotFound from './Components/ErrorPages/404';
import AdnyForms from './Forms/Adny';

import SoulFulStudentApplication from './Forms/SoulFulSynergy/studentApplication'
import SoulFulFooter from './Forms/SoulFulSynergy/components/footer';
import NewSoulfulSynergyApplications from './Forms/newSoulfulSynergy';
import SoulfulV2 from './Forms/V2';
import SoulfulV2_Demo from './Forms/V2_Demo';
import V3_New from './Forms/V3_New/index';

const App2 = () => {
  const {pathname} = useLocation();

  if (document.location.href.includes("/cea")) {
    document.location.replace('/application')
  } else if (document.location.href.includes("/nyserda-pre-survey")) {
    document.location.replace('/training-pre-survey')
  } else if (document.location.href.includes("/nyserda-post-survey")) {
    document.location.replace('/training-post-survey')
  } 

  ReactGA.initialize("AW-11021716220");
  return (
    <div>
      {pathname !== '/new-form' && pathname !== "/organizations" && pathname !== '/' ? <Header/> : null}
      <div className={`body ${pathname === "/we-act-application" ? 'weact' : null}`}>
        <Routes>
            <Route path='/old-form' element={<SoulFulStudentApplication />} />
            <Route exact path='/' element={<SoulfulV2_Demo />} />
            <Route path='/organizations' element={<V3_New />} />
            {/* <Route path='/uploads' element={<Studentuploads/>} /> */}
            <Route path='*' element={<PageNotFound/>} />
        </Routes>
      </div>

        {pathname === "/we-act-application" ? <WeActFooter/> : (pathname === "/" || pathname === "/new-form" || pathname === "/old-form" || pathname === "/organizations") ? <SoulFulFooter/>  :  <Footer/>}
    </div>
  )
}

export default App2
