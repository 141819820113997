import React, { useEffect, useState } from 'react'
import Header from '../../Components/V2/header'
import './style.css'
import SimpleRadio from '../../Components/Radio/SimpleRadio'
import Checkbox from '../../Inputs/Checkbox'
import Input from '../../Inputs/Input'
import CreatableSelect from 'react-select/creatable'
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { StandaloneSearchBox } from '@react-google-maps/api'
import SimpleRadioWithOther from '../../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from '../../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import Upload from '../../Components/Upload/upload'
import DOB from '../../Inputs/Dob'
import RadioHavingOptionChildren from '../../Components/Radio/RadioHavingOptionChildrens'
import { Translate } from 'react-auto-translate'
import MapPin from 'feather-icons-react/build/IconComponents/MapPin'
import FormButton from '../../Components/FormButton'
import getIp from 'react-public-ip';

// Static Imports
import ParagraphStep2 from './../../assets/222.png'
import ParagraphStep3 from './../../assets/333.png'
import ParagraphStep4 from './../../assets/444.png'
import ParagraphStep5 from './../../assets/555.png'
import ParagraphStep6 from './../../assets/666.png'
import ParagraphStep7 from './../../assets/777.png'
import { getModulePreferences, getStates } from '../../Apis/studentApplication'
import { toast } from 'react-toastify'
import { getCoursePreferences, getCoursesByPriority, getReferrals } from '../../Apis/courses'
import GoogleMapAddress from '../../Components/Map/map'
import { loadModules } from 'esri-loader'
import CheckboxWithExpander from '../../Components/Checkbox/CheckboxHavingChildrens'
import SimpleRadioDropdown from '../../Components/Radio/SimpleRadioDropdown'
import { UAParser } from 'ua-parser-js'
import moment from 'moment'
import { SoulfulOrganizationApplicationNew } from '../../Apis/soulfulForms'
import { EMAIL_REGEX } from '../../utils/constants'
import V3_New_Step1 from './step1'
import Submitted from './submitted'

const SoulfulV2Demo = () => {
    
    // ** Static Data
    const ethnicities = [
      "American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)", "Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)", "Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)", "Hispanic, Latino or Spanish origin (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)", "Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)", "Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)", "White (Eg: German, Irish, English, Italian, Polish, French, etc)", "I prefer not to say"
    ]

    const sex = ["Male", "Female", "Non-binary", "Transgender", "Intersex", "I prefer not to say"]

    const transgender = ["Yes", "No", "I prefer not to say"]

    const employeesCount = ["0-49", "50-249", "250-999", "1000-1499", "1500-1999", "2000 or more"]

    const pronouns = ["He/Him", "She/Her", "They/Them", "Other"]

    const secondaryLanguage = ["English", "Spanish", "Mandarin", "Russian", "Polish", "French", "Other"]

    // Simple radio with dropdown static data
    const updatedEthnicities = []
    ethnicities.forEach((item) => {
      updatedEthnicities.push(
        {label: item, value: item, isFixed: true}
      )
    })

    const updatedSex = []
    sex.forEach((item) => {
      updatedSex.push(
        {label: item, value: item, isFixed: true}
      )
    })

    const updatedTransgender = []
    transgender.forEach((item) => {
      updatedTransgender.push(
        {label: item, value: item, isFixed: true}
      )
    })

    const updatedEmployeesCount = []
    employeesCount.forEach((item) => {
      updatedEmployeesCount.push(
        {label: item, value: item, isFixed: true}
      )
    })
    
    const updatedPronouns = []
    pronouns.forEach((item) => {
      updatedPronouns.push(
        {label: item, value: item, isFixed: true}
      )
    })

    const updatedSecondaryLanguage = []
    secondaryLanguage.forEach((item) => {
      updatedSecondaryLanguage.push(
        {label: item, value: item, isFixed: true}
      )
    })


    // ** States
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [loader, setLoader] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [states, setStates] = useState([]);
    const [modulePreferences, setModulePreferences] = useState([])
    const [coursePreferences, setCoursePreferences] = useState([]);
    const [interestedCoursePreferences, setInterestCoursePreferences] = useState([]);
    const [show, setShow] = useState(false)
    // ** Check is Disadvantage Communities
    const [Lat, setLat] = useState('')
    const [Lng, setLng] = useState('')
    const [googleFormattedAddress, setGoogleFormattedAddress] = useState('')

    const [IsDisadvantage, setIsDisadvantage] = useState('no')
    const [referralList, setReferralList] = useState([])
    const [IsUnemployed, setIsUnemployed] = useState(false)
    const [IsEmployedInEvSector, setIsEmployedInEvSector] = useState(false)
    const [validateSteps, setValidateSteps] = useState({
      0: false,
      1: false,
      2: true,
      3: false,
      4: true,
      5: false,
      6: false,
    })

    // User System Info
    const [userSystemInfo, setUserSystemInfo] = useState({info: "", ip: ""})


    //
    useEffect(() => {
      getStates().then(res => {
        if(res != null){
            const data = res.data
            const statesArray = []
            for(let i = 0; i < data.length; i++){
              let state = data[i].attributes.stateName;
              statesArray.push({label: state, value: state, isFixed: true})
            }
            setStates(statesArray);
        }else{
            
        }
    }).catch(error => {
      console.log("error: ", error);
    })

      // getModulePreferences().then(res => {
      //   if (res !== null) {
      //     const data = res.data
      //     const modulePreferences = []
      //     for (let i = 0; i < data.length; i++) {
      //       modulePreferences.push(data[i].attributes.name)
      //     }
      //     setModulePreferences(modulePreferences)
      //   } else {
      //     setModulePreferences([])
      //     toast.error(<Translate>Something went wrong</Translate>)
      //   }
      // }).catch(() => {
      //   setModulePreferences([])
      //   toast.error(<Translate>Something went wrong</Translate>)
      // })
  
      getCoursesByPriority().then(res => {
        if (res !== null) {
          const responseData = res.data
          let coursePreferencesPriority = {}
          let interestedCoursePreferences = {}
          let referralPriority = {}
          let modulePriority = {}
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].attributes.type === 1) {
              coursePreferencesPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 2) {
              interestedCoursePreferences = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 3) {
              referralPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 5) {
              modulePriority = responseData[i].attributes.priorities
            }
          }

          // Get courses list
          getCoursePreferences().then(res => {
            if (res !== null) {
              const coursePreferencesTable = []
              const interestedCoursePreferencesTable = []
              const data = res.data
              for (let i = 0; i < data.length; i++) {
                if (data[i].attributes.type === 1) {
                  coursePreferencesTable.push({
                      id: data[i].id,
                    line1: data[i].attributes.line1,
                    line2: data[i].attributes.line2,
                    line3: data[i].attributes.line3
                })
                } else {
                  interestedCoursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1
                  })
                }
              }

              // set items in array according to priority

              let coursePreferencesPriorityTableData = []
              Object.keys(coursePreferencesPriority).forEach(key => {
                const priority = coursePreferencesPriority[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < coursePreferencesTable.length; i++) {
                  if (coursePreferencesTable[i].id === priority) {
                    coursePreferencesPriorityTableData.push(coursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray1 = coursePreferencesTable
              let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                  if (coursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                coursePreferencesPriorityTableData.push(notPresentPriorityArray1[i])
              }

              //////////////////////////////// For Interested Course Preferences ////////////////////////////////

              const interestedCoursePreferencesPriorityTableData = []
              Object.keys(interestedCoursePreferences).forEach(key => {
                const priority = interestedCoursePreferences[key] // 29
                for (let i = 0; i < interestedCoursePreferencesTable.length; i++) {
                  if (interestedCoursePreferencesTable[i].id === priority) {
                    interestedCoursePreferencesPriorityTableData.push(interestedCoursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray = interestedCoursePreferencesTable
              let notPresentPriorityArray = makeNewArray.filter(item => {
                  if (interestedCoursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray.length; i++) {
                interestedCoursePreferencesPriorityTableData.push(notPresentPriorityArray[i])
              }

            //  //  console.log("coursePreferencesPriorityTableData: ", interestedCoursePreferencesPriorityTableData);
              setCoursePreferences(coursePreferencesPriorityTableData)
              setInterestCoursePreferences(interestedCoursePreferencesPriorityTableData)
            } else {
              setCoursePreferences([])
              setInterestCoursePreferences([])
            }
          }).catch(() => {
            ////  console.log("error: ", error)
            setCoursePreferences([])
            setInterestCoursePreferences([])
            toast.error(<Translate>Something went wrong</Translate>)
          })

          getReferrals().then(res => {
            if (res !== null) {
              const data = res.data
              // Referral
              const ReferralPreferencesTable = []
              for (let i = 0; i < data.length; i++) {
                ReferralPreferencesTable.push({
                  name: `${data[i].attributes.name}`,
                  id: data[i].id
                })
              }

                // set items in array according to priority
                let referrralTableData = []
                Object.keys(referralPriority).forEach(key => {
                  const priority = referralPriority[key] // 29
                  ////  console.log("key: ", priority);
                  for (let i = 0; i < ReferralPreferencesTable.length; i++) {
                    if (ReferralPreferencesTable[i].id === priority) {
                      referrralTableData.push(ReferralPreferencesTable[i])
                    }
                  }
                })

                let makeNewArray1 = ReferralPreferencesTable
                let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                    if (referrralTableData.includes(item) === false) {
                      return item
                    }
                })

                for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                  referrralTableData.push(notPresentPriorityArray1[i])
                }
            //   console.log("ReferralPreferencesTable: ", ReferralPreferencesTable, referrralTableData)
            
            console.log("Sort Array of Objects Alphabetically");
            const Std_arr = referrralTableData
            const sortedList = Std_arr.sort((a, b) =>
              a.name.localeCompare(b.name));
              console.log(sortedList);
              console.log("sortedList.indexOf('other'): ", sortedList.indexOf('Other'));
              const newData = sortedList.filter(item => (item.name !== "Other" && item.name !== "Referral"))
              newData.push((sortedList.filter(item => item.name === "Referral"))[0])
              newData.push((sortedList.filter(item => item.name === "Other"))[0])
              setReferralList(newData)
            } else {
              setReferralList([])
              toast.error(<Translate>Something went wrong</Translate>)
            }
          }).catch(() => {
            setReferralList([])
            toast.error(<Translate>Something went wrong</Translate>)
          })

          getModulePreferences().then(res => {
            if (res !== null) {
              const data = res.data
              const modulePreferences = []
              for (let i = 0; i < data.length; i++) {
                modulePreferences.push({
                  name: `${data[i].attributes.name}`,
                  id: data[i].id
                })
              }

              // set items in array according to priority
              let moduleTableData = []
              Object.keys(modulePriority).forEach(key => {
                const priority = modulePriority[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < modulePreferences.length; i++) {
                  if (modulePreferences[i].id === priority) {
                    moduleTableData.push(modulePreferences[i])
                  }
                }
              })

              let makeNewArray1 = modulePreferences
              let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                  if (moduleTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                moduleTableData.push(notPresentPriorityArray1[i])
              }

              const updatedData = []
              moduleTableData.forEach((item => {
                updatedData.push(item.name)
              }))

              console.log("data:---------------", updatedData);
              setModulePreferences(updatedData)
            } else {
              setModulePreferences([])
              toast.error(<Translate>Something went wrong</Translate>)
            }
          }).catch(() => {
            setModulePreferences([])
            toast.error(<Translate>Something went wrong</Translate>)
          })
        } else {
          toast.error(<Translate>Something went wrong</Translate>)
          setCoursePreferences([])
          setInterestCoursePreferences([])
        }
      }).catch(error => {
        console.log("error: ", error)
        toast.error(<Translate>Something went wrong</Translate>)
        setCoursePreferences([])
        setInterestCoursePreferences([])
      })

      async function getIPAddress() {
        const ipv4 = await getIp.v4() || "";
        const parser = new UAParser();
        // console.log("ipv4: ", ipv4);
        setUserSystemInfo({...userSystemInfo, info: parser.getResult(), ip: ipv4})
      }
      getIPAddress()
    }, [])

    // Make Options For Who Referred You?
    const refereralOptions = []
    referralList.forEach(option => {
      refereralOptions.push(option['name'])
    })

    const [formData, setFormData] = useState({
        fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, shadow: false}, 
        
        mName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'mName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, shadow: false},
        
        address: {value: '', dataSet: {question: "Address", isRequired: true, formDataKey: 'address', isError: false, errorMessage: ''}, type: 'simpleInputWithMap', inputType: 'text', disable: true, shadow: false}, 
        
        state: {value: 'New York', dataSet: {question: "State", isRequired: true, formDataKey: 'state', isError: false, errorMessage: '', info: ''}, type: 'dropdown', inputType: 'text', disable: true, shadow: false},

        zipcode: {value: '', dataSet: {question: "Zip Code", isRequired: true, formDataKey: 'zipcode', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true, shadow: false},
  
        phoneNumber: {value: '', dataSet: {question: "Phone", isRequired: false, formDataKey: 'phoneNumber', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true, notifyMe: false, smsCommunication: true, shadow: false},
        
        isEighteen: {value: '', dataSet: {question: "Date of Birth:", options: ["Yes", "No"], isRequired: true, groupName: 'isEighteen', formDataKey: 'isEighteen', isError: false, errorMessage: ''},type: 'dob', disable: true, shadow: false, placeholder: 'DD-MM-YYYY'}, 
        
        email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: true, emailCommunication: true, shadow: false}, 
      
        partnerOrg: {value: '', dataSet: {question: "Who organized this training for you?", isRequired: true, formDataKey: 'linkedin', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', socialLink: true, disable: true, shadow: false},
        
        linkedin: {value: '', dataSet: {question: "LinkedIn URL", isRequired: false, formDataKey: 'linkedin', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', socialLink: true, disable: true, shadow: false},

        // courceInterested : {value: '', dataSet: {question: "Please indicate which course you are most interested in attending. (Select the one that most applies to you)", options: [{value: "Module 1 - Transportation Electrification (TE) Basics (3 Hours)", showChildren: false, childrens: modulePreferences}, {value: 'Module 2 - Electric Vehicle Charging Equipment (EVCE) Site Feasibility (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'Module 3 - EVCE Installation & Managed Charging (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'All of the above', showChildren: false, childrens: modulePreferences}], isRequired: true, groupName: 'courceInterested', formDataKey: 'courceInterested', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true, isVisible: true}, 

        // additionalCourse: {value: [], dataSet: {question: "Please indicate any additional courses you may be interest in taking? (Select the one(s) that most applies to you)", options: ["Battery Storage", "Construction Safety", "Design and Planning", "Electrification", "Energy Efficiency", "Energy Modeling", "Engineering, Software", "Green Roof Installation", "Renewable Energy", "Transportation", "Other"], isRequired: true, groupName: 'additionalCourse', formDataKey: 'additionalCourse', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
         
        
        // Experience and Demorgraphics---------------------------------------

        // currentEmploymentStatus: {value: '', dataSet: {question: "Select which best describes your employment situation", options: [{value: "Employed (Full Time)", showChildren: false, childrens: ["Employed full-time in clean energy", "Employed full-time not in clean energy"]}, {value: "Underemployed (Part Time)", showChildren: false, childrens: ["Employed part-time in clean energy", "Employed part-time not in clean energy"]}, {value: 'Self-employed', showChildren: false, childrens: ["Self-Employed Full Time in clean energy", "Self-Employed Full Time not in clean energy", "Self-Employed Part Time in clean energy", "Self-Employed Part Time not in clean energy"]}, {value: 'Unemployed', showChildren: false, childrens: []}], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true}, 

        // lastJobPosition: {value: '', dataSet: {question: "What is your current or last job title or position?", isRequired: true, formDataKey: 'lastJobPosition', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', placeholder:'Job Title'},

        // workOrganization: {value: '', dataSet: {question: "Affiliated Organization (Place of work)", isRequired: true, formDataKey: 'workOrganization', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', placeholder:'Place of work'},

        // workOrganizationSize: {value: '', dataSet: {question: "Number of Employees:", options: updatedEmployeesCount, isRequired: true, groupName: 'workOrganizationSize', formDataKey: 'workOrganizationSize', isError: false, errorMessage: ''},type: 'simpleRadioDropdown', disable: true, isVisible: true},

        // employedInEVsector: {value: '', dataSet: {question: "Are you currently employed in an industry related to electric vehicles or clean transportation?", options: ["Yes", "No"], isRequired: true, groupName: 'employedInEVsector', formDataKey: 'employedInEVsector', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},

        // detailsCurrentRole: {value: '', dataSet: {question: "Please provide details about your current industry and role:", isRequired: true, formDataKey: 'detailsCurrentRole', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: true},
         
        // futurePlan: {value: '', dataSet: {question: "Do you plan to work in this kind of projects in the future?", options: ["Yes", "No"], isRequired: true, groupName: 'futurePlan', formDataKey: 'futurePlan', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true, isVisible: true},
        
        // pastExperienceAreas: {value: [], dataSet: {question: "Do you have any past experience with any of the following areas?", options: ["Electrical Vehicles", "Electric Vehicle Charging Stations", "Electrical Panels", "Battery Storage", "Property Management", "Facilities Management", "Construction Management", "Lighting & Electrical Efficiency", "HVAC & Mechanical", "Plumbing & Heating", "Carpentry & Envelope", "Solar PV", "Other Renewable Energy", "None of the above"], isRequired: true, groupName: 'pastExperienceAreas', formDataKey: 'pastExperienceAreas', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 

        // certifications: {value: [], otherValue: '', dataSet: {question: "Do you have any green industry certifications?", options: certificationsList, isRequired: true, groupName: 'certifications', formDataKey: 'certifications', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true},      

        // levelExperience: {
        //   value: [],
        //   otherValue: '',
        //   dataSet: {
        //     question: "Please rate your level of experience for the following fields:",
        //     options: [
        //       { option: "Lighting", key: "experienceLighting", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'lightingEea' },
        //       { option: "HVAC", key: "experienceHVAC", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'hvac' },
        //       { option: "Motors and Drives", key: "experienceMotorsDrivers", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'motorAndDrives' },
        //       { option: "Appliances", key: "experienceAppliances", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'appliances' },
        //       { option: "Heating", key: "experienceHeating", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'heating' },
        //       { option: "Hot Water", key: "experienceHotWater", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'hotWater' },
        //       { option: "Building Envelope", key: "experienceBuildingEnvelope", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'buildingEnvelope' },
        //       { option: "Combustion Applications", key: "experienceCombustionApplications", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'combustionApplications' },
        //       { option: "Solar PV", key: "experienceSolarPV", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'solarPV' },
        //       { option: "Wind", key: "experienceWind", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'wind' },
        //       { option: "Solar Thermal", key: "experienceSolarThermal", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'solarThermal' },
        //       { option: "Geothermal", key: "experienceGeoThermal", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'geothermal' },
        //       { option: "General Residential", key: "experienceGenResidential", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'generalResidential' },
        //       { option: "General Commercial", key: "experienceGenCommercial", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'generalCommercial' },
        //       { option: "Plumbing", key: "experiencePlumbing", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'plumbing' },
        //       { option: "Electrical", key: "experienceElectrical", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'electrical' },
        //       { option: "Architecture, Engineering or related", key: "experienceArchEngg", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'architectureEngineeringRelated' },
        //       { option: "None", key: "experienceNone", children: [], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'noneOne' },
        //       { option: "Other", key: "otherExperience", children: ["Introductory Level Skill", "General Level Skill", "Advanced Level Skill"], showChildren: false, subValue: 'Introductory Level Skill', groupName: 'otherEee' }
        //     ],
        //     isRequired: true,
        //     groupName: 'levelExperience',
        //     formDataKey: 'levelExperience',
        //     isError: false,
        //     errorMessage: '',
        //     isNoneSelect: false,
        //     isOtherSelect: false
        //   },
        //   type: 'checkboxWithExpander',
        //   disable: true
        // },

        // interest: {value: [], otherValue: '', dataSet: {question: "Please select all that interest you:", options: ['Energy Efficiency','Renewable Energy',"General Residential Construction","General Commercial Construction","Building Shell/Envelope Improvements","Plumbing","Electrical","Architecture, Engineering or Related","HVAC Installation/Technician","Photovoltaic (PV) Technician","Solar Thermal Technician", "Battery Storage", "Grid Reliability", "Demand Management", "Automotive Vehicle Charging", "No Preference", "Other"], isRequired: true, groupName: 'interest', formDataKey: 'interest', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true},

        // evceExperience: {value: '', dataSet: {question: "Experience with EVCE", options: ["Begineer", "Intermediate", "Advanced"], isRequired: true, groupName: 'evceExperience', formDataKey: 'evceExperience', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        // pastEVCEProjects: {value: '', dataSet: {question: "Number of past EVCE Projects", isRequired: true, formDataKey: 'pastEVCEProjects', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: true},
  
        // evSupport: {value: '', dataSet: {question: "Would you be interesting in receiving EV charging consulting support or having a site feasibility assessment conducted for one or more of your sites?", options: ["Yes", "No"], isRequired: true, groupName: 'evSupport', formDataKey: 'evSupport', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},  
        
        // Experience and Demorgraphics---------------------------------------
        
        
        currentEmploymentStatus: {value: '', dataSet: {question: "Select which best describes your employment situation", options: [{value: "Employed (Full Time)", showChildren: false, childrens: ["Employed full-time in clean energy", "Employed full-time not in clean energy"]}, {value: "Underemployed (Part Time)", showChildren: false, childrens: ["Employed part-time in clean energy", "Employed part-time not in clean energy"]}, {value: 'Self-employed', showChildren: false, childrens: ["Self-Employed Full Time in clean energy", "Self-Employed Full Time not in clean energy", "Self-Employed Part Time in clean energy", "Self-Employed Part Time not in clean energy"]}, {value: 'Unemployed', showChildren: false, childrens: []}], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true}, 
        
        ethnicity: {value: '', dataSet: {question: "Which of the following ethnicities do you most closely identify with?", options: updatedEthnicities, isRequired: true, groupName: 'ethnicity', formDataKey: 'ethnicity', isError: false, errorMessage: ''},type: 'simpleRadioDropdown', disable: true},

        gender: {value: '', city: '', stateShortcode: '', dataSet: {question: "Do you identify with any of the following?", options: updatedSex, isRequired: true, groupName: 'sex', formDataKey: 'sex', isError: false, errorMessage: ''},type: 'simpleRadioDropdown', disable: true},

        // pronoun: {value: '', otherValue: '', dataSet: {question: "How would you like to be addressed (your pronouns)?", options: updatedPronouns, isOther: true, isRequired: false, groupName: 'pronoun', formDataKey: 'pronoun', isError: false, errorMessage: ''},type: 'simpleRadioDropdown', disable: true},

        // transgender: {value: '', dataSet: {question: "Do you identify as transgender?", options: updatedTransgender, isRequired: true, groupName: 'transgender', formDataKey: 'transgender', isError: false, errorMessage: ''},type: 'simpleRadioDropdown', disable: true},
        
        highestEducation: {value: '', dataSet: {question: "What is your highest level of completed education?", options: ["Have not completed high school or equivalent", "High School/HSE/GED", "Associates Degrees", "Bachelors Degrees", "Graduate Degree", "Post Graduate"], isRequired: true, groupName: 'highestEducation', formDataKey: 'highestEducation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},
        
        NYSERDA_priorityPopulations: {value: [], dataSet: {question: "Individuals identifying with one or more of the following distinctions may qualify you for additional resources. Please let us know which, if any, you identity with:", options: ["Veterans", "Native Americans", "Individuals with disabilities", "Immigrant", "Refugee", "English as a second language (ESL)", "1st Generation College", "2nd Generation College", "Low income individuals - (60% of NYS median income or receiving public assistance)", "Unemployed plant workers", "Previously incarcerated Individuals", "Homeless Individuals", "16 to 24 years olds in work preparedness training programs that include energy related technical Training", "Single parents", "None of the above"], isRequired: true, groupName: 'NYSERDA_priorityPopulations', formDataKey: 'NYSERDA_priorityPopulations', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true},

        // reasonEnroll: {value: [], otherValue: '', dataSet: {question: "What is your main educational objective for taking this class: (Select the one that most applies to you)", options:["Certification required to participate in an incentive program","To prepare for industry recognized certifications and/or receive CEUs","Maintain a license or certification (required professional CEU)","Prepare for an apprenticeship/trades program","My employer recommended it","Help me get a job in construction or clean energy field","Career advancement (obtain a promotion or get a better job than the one I have now)","It is a part of a legally mandated program","To meet specific needs indicated by a client or customer","Required prerequisite for other training I want to take","None of the above", "Other"], isRequired: true, groupName: 'reasonEnroll', formDataKey: 'reasonEnroll', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true},

        // secondaryLanguage: {value: [], otherValue: '', dataSet: {question: "If we could offer our course in another language, which would you prefer? (Select the one that most applies to you)", options:["Certification required to participate in an incentive program","To prepare for industry recognized certifications and/or receive CEUs","Maintain a license or certification (required professional CEU)","Prepare for an apprenticeship/trades program","My employer recommended it","Help me get a job in construction or clean energy field","Career advancement (obtain a promotion or get a better job than the one I have now)","It is a part of a legally mandated program","To meet specific needs indicated by a client or customer","Required prerequisite for other training I want to take","None of the above", "Other"], isRequired: true, groupName: 'secondaryLanguage', formDataKey: 'secondaryLanguage', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 

        // secondaryLanguage: {value: '', otherValue: '', dataSet: {question: "What language would you prefer the course to be in?", options: updatedSecondaryLanguage, isRequired: true, groupName: 'secondaryLanguage', formDataKey: 'secondaryLanguage', isError: false, errorMessage: ''}, type: 'simpleRadioDropdown', disable: true}, 
        
        // improveUnderstandingTE: {value: [], otherValue: '', dataSet: {question: "This course will improve my understanding of Transportation and Electrification", options:["Agree","Disagree","I am not sure","My understanding will remain the same"], isRequired: true, groupName: 'improveUnderstandingTE', formDataKey: 'improveUnderstandingTE', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true},  
        
        // sourceApplication: {value: '', dataSet: {question: "How did you hear about this training program?", options: refereralOptions, isRequired: true, groupName: 'sourceApplication', formDataKey: 'sourceApplication', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},

        // referredBy: {value: '', dataSet: {question: "If you chose Referral, who referred you?", isRequired: false, formDataKey: 'referredBy', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: false}
    })

    // Dropdown Componenet
    const handleSelectState = (item) => {
      console.log(item);
      setFormData({...formData, state: {...formData['state'], 'value': item.value, 'dataSet': {...formData['state']['dataSet'], info: item.value != "New York" ? "Non-New York residents might not be eligible for training at no cost" : ""}}})
    }

    // Address Component
    const handleAdderssByMap = () => {
      setShow(true)
    }

    const handleCancel = () => {
      setShow(false)
    }

    const [referencess, setReference] = useState(null)
    const onLoad = ref => {
      let searchBox = null
      searchBox = ref
      setReference(ref)
    };
  
    const search = async (googleAddress) => {
      console.log("googleAddress: ", googleAddress);
  
      const [Map, MapView, FeatureLayer, Search] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/FeatureLayer",
        "esri/widgets/Search"
      ])
  
      try {
        const myMap = new Map({
        basemap: "streets-relief-vector"
        })
  
        console.log("map: ", myMap)
    
        const myView = new MapView({
            container: "viewDiv",
            map: myMap,
            center: [-76, 42.8], 
            zoom: 7
        })
  
        console.log("map 1: ", myView)
    
        myView.popup.dockOptions = {
        // Disable the dock button so users cannot undock the popup
        buttonEnabled: false
        }
    
        myView.ui._removeComponents(["attribution"])
    
        const Interim_DAC = new FeatureLayer({
        url: "https://services7.arcgis.com/ZR2wjW0JGwgm9bhz/arcgis/rest/services/FinalDisadvantagedCommunities/FeatureServer",
        popupTemplate: {
        // Enable a popup
        title: "Interim DAC" // Show attribute value
        // content: "Clicked Point" // Display text in pop-up
        }
        })
  
        myMap.add(Interim_DAC)           
        // console.log("map 2: ", Interim_DAC)
    
        const searchWidget = new Search({
          view: myView
        })
  
        myView.ui.add(searchWidget, {
            position: "top-left",
            index: 2
        })
  
        searchWidget.search(googleAddress).then(res => {
          // console.log("response: ", res?.results[0]['results'][0]['feature']['geometry']);
          const geometry = res?.results[0]['results'][0]['feature']['geometry']
          // console.log("geometry: ", res?.results[0]['results'][0]['feature']['geometry']);
          getAddress(geometry);
        }).catch(error => {
          // console.log("geometry error: ", error);
        })
        
        function resolveValue(value) {
          console.log("map 5: ", value);
            var geoId = null;
            var userMessage = "";
            console.log("value 12: ", value);
            if(value == null){
                userMessage = "";
                console.log("userMessage - " + userMessage);
                // alert('Address Not Meet')
                setIsDisadvantage('no')
                // document.getElementById('resultDiv').innerHTML = userMessage
            }
            else if(value == "no data found"){
                userMessage = " is outside a DAC";
                console.log("userMessage - " + userMessage);
                // alert('Address Not Meet')
                setIsDisadvantage('no')
                // document.getElementById('resultDiv').innerHTML = userMessage
            }
            else if(value.features.length > 0){
                geoId = value.features[0].attributes.GEOID;
                userMessage = "<br>"+ "This address meets the interim criteria identified for a disadvantaged community.";
                console.log("userMessage - " + userMessage);
                // alert('Address Meet')
                setIsDisadvantage('yes')
                // document.getElementById('resultDiv').innerHTML = userMessage
            }
            else{
                userMessage = "<br>"+ "This address does not meet the interim criteria identified for a disadvantaged community.";
                console.log("userMessage - " + userMessage);
                // alert('Address Not Meet')
                setIsDisadvantage('no')
                // document.getElementById('resultDiv').innerHTML = userMessage
            }
        }
  
        function getAddress(point) {
            const query = Interim_DAC.createQuery()
            query.geometry = point  
            query.spatialRelationship = "intersects"
            query.returnGeometry = false
            query.outFields = ["GEOID"]
            Interim_DAC.queryFeatures(query)
            .then(function(value) {
                resolveValue(value)
            }, function(err) {
                console.error("This is an error", err)
                // this.error()
            })
        }
    
        searchWidget.on("select-result", function(event) {
            // setIsAddressSearched(true)
            getAddress(event.result.feature.geometry)
        })
        searchWidget.on("search-clear", function() {
            // console.log("cleared")
            // setIsAddressSearched(false)
            // setIsDisadvantage(false)
        })
      } catch (error) {
        // alert('Address Not Meet')
        setIsDisadvantage('no')
      }
    }
  
    const onPlacesChanged = () => {
      let selectedAddress = referencess.getPlaces()
      let latitude = selectedAddress[0].geometry.location.lat()
      let longitude = selectedAddress[0].geometry.location.lng()
  
      let addressComponentsArray = [];
      addressComponentsArray = selectedAddress[0].address_components;
      let zipcode = ''
      let state = ''
      let city = ''
      for (let i = 0 ; i < addressComponentsArray.length ; i++) {
        let addObjtypes = addressComponentsArray[i].types
        if (addObjtypes.includes('postal_code')) {
            zipcode = `${addressComponentsArray[i].long_name}`
            console.log("addObjtypes: ", addressComponentsArray[i].long_name);
            // break;
        }
        
        if (addObjtypes.includes('administrative_area_level_1')) {
            state = `${addressComponentsArray[i].long_name}`
            console.log("addObjtypes: ", addressComponentsArray[i].long_name);
            // break;
        }
  
        if (addObjtypes.includes('locality')) {
          city = addressComponentsArray[i].long_name
        }
      }
      
      console.log("event: ", selectedAddress[0].geometry.location.lat(), selectedAddress[0].geometry.location.lng(), zipcode)
      setLat(latitude); setLng(longitude);
      setFormData({...formData, address: {...formData['address'], 'value': selectedAddress[0].formatted_address}, zipcode: {...formData['zipcode'], 'value': zipcode}, state: {...formData['state'], 'value': state}})
      search(selectedAddress[0].formatted_address)
    };
  

    const setAddressByMap = (addressComponent) => {
      console.log("addressComponent: ", addressComponent);
      setFormData({...formData, address: {...formData['address'], 'value': addressComponent.formattedAddress}, zipcode: {...formData['zipcode'], 'value': addressComponent.zipcode}, state: {...formData['state'], 'value': addressComponent.state}})
      setLat(addressComponent.latitude)
      setLng(addressComponent.longitude)
      setGoogleFormattedAddress(addressComponent.formattedAddress)
      search(addressComponent.formattedAddress)
    }

    // Stepper Actions
    const next = () => {
      if (activeStep <= 5) {
        let formattedStep = activeStep + 1
        if (activeStep + 1 === 2) {
          formattedStep = 3
        }
        if (activeStep + 1 === 4) {
          formattedStep = 5
        }
        setActiveStep(formattedStep)
        // window.scrollTo(0, 0)
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }, 1000)
      }
    }

    const previous = () => {
        if (activeStep > 0) {
          let formattedStep = activeStep - 1
          if (activeStep - 1 === 4) {
            formattedStep = 3
          }
          if (activeStep - 1 === 2) {
            formattedStep = 1
          }
          setActiveStep(formattedStep)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
    }

    // Functions
    const [IsLoading, setIsLoading] = useState(false)
    function validateData(){
      let functionReturn = true
      let validateFormData = formData
      
      let startPoint = 0;
      let endPoint = 0;
      switch(activeStep) {
        case 0: startPoint = 0;
        endPoint = 14;
        break

        case 1: startPoint = 9;
        endPoint = 10;
        break

        case 2: startPoint = 11;
        endPoint = 24;
        break

        case 3: startPoint = 25;
        endPoint = 31 - (factor());
        break

        case 4: startPoint = 32 - (factor());
        endPoint = 37 - (factor());
        break

        case 5: startPoint = 0;
        endPoint = Object.keys(formData).length;
        break
      }
      
      // Bypass questions
      const bypassQuestions = ["lastJobPosition","workOrganization","workOrganizationSize","employedInEVsector","detailsCurrentRole","futurePlan","pastExperienceAreas","certifications","levelExperience","interest","evceExperience","pastEVCEProjects","evSupport","reasonEnroll","secondaryLanguage","improveUnderstandingTE","sourceApplication","referredBy"]
    
      Object.keys(validateFormData).forEach((eachQuestion, i) => {
        // Checking Required ------------------------------------------------------------------------------------------------------------------
        if (!bypassQuestions.includes(eachQuestion) && eachQuestion !== 'emailVerified' && eachQuestion !== "phoneVerified" && (i >= startPoint && i <= endPoint) && validateFormData[eachQuestion]['dataSet']['isRequired'] === true) {
          console.log("questions:-------------", eachQuestion, activeStep);
          if (validateFormData[eachQuestion]?.['value'] !== null) {
            if (validateFormData[eachQuestion]?.['dataSet']?.['isRequired'] && ((validateFormData[eachQuestion]?.['value']).length <= 0 || validateFormData[eachQuestion].value === null)) {
              validateFormData[eachQuestion]['dataSet']['isError'] = true
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
              functionReturn = false
            } else {
              if (validateFormData[eachQuestion]['dataSet']) {
                validateFormData[eachQuestion]['dataSet']['isError'] = false
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              }
            }
          }
        if (validateFormData[eachQuestion]['type'] === 'simpleRadioDropdown') {
          const value = validateFormData[eachQuestion]['value']
          if (typeof value === "object") {
            if (value.label === "Other") {
              const otherValue = validateFormData[eachQuestion]['otherValue']
              if (otherValue.length) {
                validateFormData[eachQuestion]['dataSet']['isError'] = false
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                if (functionReturn !== false) {
                  functionReturn = true
                }
              } else {
                validateFormData[eachQuestion]['dataSet']['isError'] = true
                validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
                functionReturn = false
              }
            } else {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              if (functionReturn !== false) {
                functionReturn = true
              }
            }
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
        }
          
          // Checking Required ------------------------------------------------------------------------------------------------------------------
          
          if (eachQuestion === 'ethnicity') {
            const value = validateFormData[eachQuestion]['value']
            if (typeof value === "string") {
              validateFormData[eachQuestion]['dataSet']['isError'] = true
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
              functionReturn = false
            } else {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
              if (functionReturn !== false) {
                functionReturn = true
              }
            }
          }
        }

        // validate email
        if (eachQuestion === 'email') {
          if (validateFormData[eachQuestion]['value']) {
            if (!(EMAIL_REGEX).test(validateFormData[eachQuestion]['value'])) {
              validateFormData[eachQuestion]['dataSet']['isError'] = true
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Invalid email'
              functionReturn = false
            } else {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          }
        }

      })

      setValidateSteps({...validateSteps, [activeStep]: functionReturn})

      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      setFormData(validateFormData)
      return functionReturn
    }

    // Submit Form ----------------------------------------------------------------------------------
    let dateString = new Date();
    dateString = moment(dateString).format('YYYY-MM-DD');
    const handleSubmit = () => {
      console.log("formData validate:-----------------", validateData());
      
      if (validateData()) {
        setLoader(true)
        console.log("formdata:------------------------------", {
          email: formData['email']['value'],
          firstName: formData['fName']['value'],
          lastName: formData['mName']['value'],
          primaryPhoneNumber: (formData['phoneNumber']['value']).replace(/[^0-9]/g, ''),
          // receiveSMSnotification: formData.phoneNumber.smsCommunication,
          address: formData['address']['value'],
          state: formData['state']['value'],
          zipcode: `${formData['zipcode']['value']}`,
          googleAddress: googleFormattedAddress,
          lat: `${Lat}`,
          lng: `${Lng}`,
          dob: moment(formData['isEighteen']['value']).format('YYYY-MM-DD'),
          linkedin: formData['linkedin']['value'],
          partnerOrg: formData['partnerOrg']['value'],
          // interestedCourse: updatedInterestedCourse,
          // coursePreference: updatedInterestedCourse !== formData['courceInterested']['value'] ? formData['courceInterested']['value']: '',
          // additionalCourse: (formData['additionalCourse']['value']).map(item => item).join(','),
          currentEmploymentStatus: formData['currentEmploymentStatus']['value'],
          // lastJobPosition: "N/A",
          // workOrganization: "N/A",
          // workOrganizationSize: "N/A",
          // employedInEVsector: false,
          // detailsCurrentRole: "N/A",
          // futurePlan: "N/A",
          // pastExperienceAreas: "N/A",
          // certifications: "N/A",
          // levelExperience: ((formData['levelExperience']['value']).map(item => item).join(',')) + `${formData['levelExperience']['otherValue']}`,
          // ...experienceObject,
          highestEducation: formData['highestEducation']['value'],
          // experienceEVCE: "N/A",
          // EVCEProjects: 0,
          // receivingEV: "N/A",
          // interests: "N/A",
          ethnicity: typeof formData['ethnicity']['value'] === "object" ? formData['ethnicity']['value']['value'] : '',
          gender: typeof formData['gender']['value'] === "object" ? formData['gender']['value']['value'] : '',
          // pronoun: typeof formData['pronoun']['value'] === "object" ? formData['pronoun']['value']['value'] : '',
          // genderIdentity: typeof formData['transgender']['value'] === "object" ? formData['transgender']['value']['value'] : '',
          priorityPopulations: (formData['NYSERDA_priorityPopulations']['value']).map(item => item).join(','),
          // courseObjective: (formData['reasonEnroll']['value']).map(item => item).join(','),
          // futureLanguagePrefrence: typeof (formData['secondaryLanguage']['value']) === "object" ? (formData['secondaryLanguage']['value']['value']) : '',
          // courseWillImproveTransportationElectrification: "",
          // sourceApplication: `${formData['sourceApplication']['value']}${formData['referredBy']['value'].length > 0 ? ' - ' + formData['referredBy']['value'] : ''}`,
          version: "v1_sept_2024",
          NYSERDA_disadvantagedCommunity: IsDisadvantage || 'no',
          formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
          userData: userSystemInfo
        });

        SoulfulOrganizationApplicationNew({data: {
          email: formData['email']['value'],
          firstName: formData['fName']['value'],
          lastName: formData['mName']['value'],
          primaryPhoneNumber: (formData['phoneNumber']['value']).replace(/[^0-9]/g, ''),
          // receiveSMSnotification: formData.phoneNumber.smsCommunication,
          address: formData['address']['value'],
          state: formData['state']['value'],
          zipcode: `${formData['zipcode']['value']}`,
          googleAddress: googleFormattedAddress,
          lat: `${Lat}`,
          lng: `${Lng}`,
          dob: moment(formData['isEighteen']['value']).format('YYYY-MM-DD'),
          partnerOrg: formData['partnerOrg']['value'],
          linkedin: formData['linkedin']['value'],
          // interestedCourse: updatedInterestedCourse,
          // coursePreference: updatedInterestedCourse !== formData['courceInterested']['value'] ? formData['courceInterested']['value']: '',
          // additionalCourse: (formData['additionalCourse']['value']).map(item => item).join(','),
          currentEmploymentStatus: formData['currentEmploymentStatus']['value'],
          // lastJobPosition: "N/A",
          // workOrganization: "N/A",
          // workOrganizationSize: "N/A",
          // employedInEVsector: false,
          // detailsCurrentRole: "N/A",
          // futurePlan: "N/A",
          // pastExperienceAreas: "N/A",
          // certifications: "N/A",
          // // levelExperience: ((formData['levelExperience']['value']).map(item => item).join(',')) + `${formData['levelExperience']['otherValue']}`,
          // ...experienceObject,
          highestEducation: formData['highestEducation']['value'],
          // experienceEVCE: "N/A",
          // EVCEProjects: 0,
          // receivingEV: "N/A",
          // interests: "N/A",
          ethnicity: typeof formData['ethnicity']['value'] === "object" ? formData['ethnicity']['value']['value'] : '',
          gender: typeof formData['gender']['value'] === "object" ? formData['gender']['value']['value'] : '',
          // pronoun: typeof formData['pronoun']['value'] === "object" ? formData['pronoun']['value']['value'] : '',
          // genderIdentity: typeof formData['transgender']['value'] === "object" ? formData['transgender']['value']['value'] : '',
          priorityPopulations: (formData['NYSERDA_priorityPopulations']['value']).map(item => item).join(','),
          // courseObjective: (formData['reasonEnroll']['value']).map(item => item).join(','),
          // futureLanguagePrefrence: typeof (formData['secondaryLanguage']['value']) === "object" ? (formData['secondaryLanguage']['value']['value']) : '',
          // courseWillImproveTransportationElectrification: formData['improveUnderstandingTE']['value'],
          // courseWillImproveTransportationElectrification: "",
          // sourceApplication: `${formData['sourceApplication']['value']}${formData['referredBy']['value'].length > 0 ? ' - ' + formData['referredBy']['value'] : ''}`,
          version: "v1_sept_2024",
          NYSERDA_disadvantagedCommunity: IsDisadvantage || 'no',
          formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
          userData: userSystemInfo
        }}).then(res => {
          if (res !== null) {
            setActiveStep(activeStep + 1)
          }
          setLoader(false)
        }).catch(error => {
          setLoader(false)
          console.log("error: ", error);
          toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
        })
      } else {
        toast.error(<Translate>Please Fill All The Required Fields</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
      }
    }

    const submit = () => {
      handleSubmit()
      setActiveStep(activeStep + 1)
    }

    const singleColumnQuestionsKey = ['employedInEVsector', 'futurePlan', 'evceExperience', 'evSupport', 'ethnicity', 'sex', 'secondaryLanguage', 'improveUnderstandingTE', 'improveUnderstandingTE']
    const threeColumnQuestionsKey = ['sourceApplication']

    // Question content
    const getContent = (questionObject, singleOrMulti) => {
      if (questionObject !== null) {
        switch(questionObject.type) {
    
          case "simpleRadio":  return (
            <SimpleRadio activeStep={activeStep} className={singleOrMulti} formType="soulfulv2" divisions={singleColumnQuestionsKey.includes(questionObject['dataSet']['formDataKey']) ? 'onefr' : threeColumnQuestionsKey.includes(questionObject['dataSet']['formDataKey']) ? 'threefr' : null} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
          )
    
          case "simpleCheckbox":  return (
            <Checkbox activeStep={activeStep} className={singleOrMulti} formType="soulfulv2" item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
          )
          
          case "simpleInput":  return(
            <Input className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} formType="new-form" setBtnDisabled={setBtnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData} setFormData={setFormData}/>
          )
          
          case "dropdown":  return (
            <div className={`single-group-child no-shadow ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
              <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
              <div className='ui form'>
                <div className='field'>
                  {/* options={states} */}
                    <CreatableSelect options={states} onChange={(item) => handleSelectState(item)} isDisabled={btnDisabled} placeholder="Select State" className='react-select' styles={{width: '100%', height: '53px'}} value={{label: formData.state.value, value: formData.state.value, isFixed: true}} classNamePrefix='select'/>
                    {/* onChange={(item) => handleSelectState(item)} */}
                </div>
            </div> 
            {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
            {questionObject['dataSet']['info'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['info']}</Translate></p> : null}
            </div>
          )

          case "simpleRadioDropdown":  return (
            <SimpleRadioDropdown activeStep={activeStep} className={singleOrMulti} formType="soulfulv2" divisions={singleColumnQuestionsKey.includes(questionObject['dataSet']['formDataKey']) ? 'onefr' : threeColumnQuestionsKey.includes(questionObject['dataSet']['formDataKey']) ? 'threefr' : null} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
            // <div className={`single-group-child ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
            //   <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
            //   <div className='ui form'>
            //     <div className='field'>
            //       {/* options={states} */}
            //         <Select options={updatedEthnicities} onChange={(item) => handleSelectState(item)} isDisabled={btnDisabled} placeholder="Select State" className='react-select' value={{label: formData.state.value, value: formData.state.value, isFixed: true}} classNamePrefix='select'/>
            //         {/* onChange={(item) => handleSelectState(item)} */}
            //     </div>
            // </div> 
            // {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
            // </div>
          )
    
          case "simpleInputWithMap": return (
            <div className={`single-group-child no-shadow ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span>
            {!btnDisabled ? 
            <Badge role={'button'} className="float-end" color='warning' style={{color: 'black'}} onClick={() => handleAdderssByMap()}><MapPin className="primary" size={18} stroke="black"/><Translate>Locate on Map</Translate></Badge>
              // <span className="fs-4" role={'button'} onClick={() => handleAdderssByMap()}>{`(Click to Locate on Map)`}</span>
              : null
            }
            </strong> 
            <div className='ui form'>
              <div className='field'>
                  {/* onLoad={onLoad} onPlacesChanged={onPlacesChanged} */}
              <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                  <input type="text" style={{height: '53px'}} value={formData.address.value} disabled={btnDisabled} className="form-control" name="address" id="address" placeholder={"Enter Address"} onChange={(event) => setFormData({...formData, ['address']: {...formData['address'], 'value': event.target.value}})}/>
              </StandaloneSearchBox>
              </div>
          </div> 
          {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
          </div>
          )
    
          case "radioWithOther":  return (
              <SimpleRadioWithOther activeStep={activeStep} className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
          )
    
          case "checkboxWithOther":  return (
              <SimpleCheckboxWithOther activeStep={activeStep} formType="soulfulv2" divisions={singleColumnQuestionsKey.includes(questionObject['dataSet']['formDataKey']) ? 'onefr' : null} className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
          )
          
          case "upload":  return (
              <Upload className={singleOrMulti} file={''} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
          )
          
          case "dob":  return (
              <DOB className={singleOrMulti} file={''} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
          ) 
          
          case "radioWithExpander":  return (
            <RadioHavingOptionChildren activeStep={activeStep} className={singleOrMulti} item={questionObject['dataSet']} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
              // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
          )

          case "checkboxWithExpander":return(
            <CheckboxWithExpander activeStep={activeStep} className={singleOrMulti} coursesList={[]} formType="soulfulv2" item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
          )
        }
      }
    }  

    console.log("active step:--------------", activeStep);

    const factor = () => {
      if (IsUnemployed && IsEmployedInEvSector) {
        return 1
      } else if (IsUnemployed && !IsEmployedInEvSector) {
        return 2
      } else if (!IsUnemployed && IsEmployedInEvSector) {
        return 1
      } else if (!IsUnemployed && !IsEmployedInEvSector) {
        return 1
      } else {
        return 0
      }
    }

    // Resolve Steps
    const resolveStep = () => {
      switch(activeStep) {
        case 0: return <V3_New_Step1 start={0} end={37} setFormData={setFormData} formData={formData} next={next} previous={previous} component={getContent}/>
        case 1: return <Submitted />
        default: return <V3_New_Step1 start={0} end={37} setFormData={setFormData} formData={formData} next={next} previous={previous} component={getContent}/>
      }
    }

    return (
      <div className='main-container' style={{backgroundImage: "url('/V2/background-image.png')"}}>
        <div id='viewDiv' style={{height: '10px', opacity: 0}}></div>
        <Header/>
        {/* <Paragraph activeStep={activeStep} content={paragraphData[activeStep]}/> */}
        {resolveStep()}
        {activeStep === 0 && <div className='btn-container'>
          <div className='inner-btn-container'>
            <FormButton styles={{backgroundColor: !validateSteps[activeStep] ? 'gray' : '#0096D8'}} captchaVerified={!validateSteps[activeStep]} text={"Submit"} handleSubmit={() => submit()} loading={loader}/>
          </div>
        </div>
        }
        {/* For developmenet */}
        {/* <FormButton styles={{backgroundColor: !validateSteps[activeStep] ? 'gray' : 'blue'}} text={activeStep === 5 ? "Submit" : "Next"} handleSubmit={activeStep === 5 ? () => submit() : () => next()}/> */}
      
        <Modal isOpen={show} onClosed={() => handleCancel()} className="modal-dialog-centered">
          <ModalHeader>
            <Translate>
              Mark Address
            </Translate>
          </ModalHeader>
          <ModalBody>
            <GoogleMapAddress handleCancel={handleCancel} setAddressByMap={setAddressByMap}></GoogleMapAddress>
          </ModalBody>
        </Modal>
      </div>
    )
}

export default SoulfulV2Demo